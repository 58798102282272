
// This function replaces placeholders in the FAQ data with the actual values of the stations and prices, since we pass only the translated text with the placeholders
export const replacePlaceholders = (data: Record<string, {question: string, answer: string}>, fromStation: string, toStation: string, cheapestPrice: number, mostExpensivePrice: number, journeyLength: string, nearbyStation: string) => {
    return Object.entries(data).map((faq) => {
        faq[1] = {
            question: faq[1].question
                .replace('%departureStation%', fromStation)
                .replace('%destinationStation%', toStation ?? nearbyStation)
                .replace('%nearestCity%', nearbyStation),
            answer: faq[1].answer
                .replace('%departureStation%', fromStation.trim())
                .replace('%destinationStation%', toStation ?? nearbyStation)
                .replace('%cheapestPPR%', `${cheapestPrice}€`)
                .replace('%mostExpensivePPR%', `${mostExpensivePrice}€`)
                .replace('%journeyLength%', journeyLength)
                .replace('%nearestCity%', nearbyStation)
        };
        return faq[1];
    });
}