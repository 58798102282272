import {useEffect} from "react";
import {validateSearchResultsErrorType} from "../../../api/functions/validateSearchResultsErrorType.ts";
import VehicleType from "../../../types/VehicleType.type.ts";
import {setVehicleType} from "../../../reducers/vehicleTypeSlice.ts";
import {setAmenities} from "../reducers/functions/bookingSlice.ts";
import useNavigateToSearch from "./useNavigateToSearch.ts";
import {useParams} from "react-router";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";

/**
 * Goes through the data and sets the vehicle type and amenities if they are missing
 * @param error
 * @param data
 * @param isLoading
 * @param setLoadingVehicle
 */
export const useSelectVehicleOnReload = (error, data, isLoading, setLoadingVehicle) => {
    const {navigateToSearchResults} = useNavigateToSearch()
    const params = useParams();
    const dispatch = useAppDispatch();
    // On complete, if something is missing return to search results (upon refresh / landing)
    useEffect(() => {
        if (error && validateSearchResultsErrorType(error)) {
            if (!data || error){
                navigateToSearchResults();
            }
        }

        if (data && !isLoading) {
            const vehicleGroup = Object.values(data?.data?.gbt?.vehicles || {}).find((vehicleGroup) => {
                return vehicleGroup.some((vehicle: VehicleType) => vehicle.id == params.vehicleGroupId);
            });

            const selectedVehicle = vehicleGroup ? vehicleGroup.find((vehicle: VehicleType) => vehicle.id == params.vehicleGroupId) : null;


            if (!selectedVehicle) {
                navigateToSearchResults();
            } else {
                dispatch(setVehicleType(selectedVehicle));
                if (selectedVehicle.amenities?.length) {
                    const initialAmenities = {}
                    selectedVehicle.amenities.forEach((amenity) => {
                        initialAmenities[amenity.id] = {
                            count: 0,
                            maxQuantity: amenity.maxQuantity,
                            returnCount: 0
                        }
                    })

                    dispatch(setAmenities(initialAmenities))
                }
                setLoadingVehicle(false);
            }
        }
    }, [isLoading, data])
}