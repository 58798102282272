import VehicleType from "../../../types/VehicleType.type.ts";
import {VehicleCardNative} from "../features/vehicleCard/components/VehicleCardNative.tsx";
import VehicleCard from "../features/vehicleCard/components/VehicleCard.tsx";
import useIsDesktop from "../../../hooks/selector/useIsDesktop.ts";
import useIsMobile from "../../../hooks/selector/useIsMobile.ts";
import useIsTablet from "../../../hooks/selector/useIsTablet.ts";

interface VehicleCardWrapperProps {
    vehicleType: VehicleType;
    duration: number;
    isIntegration: boolean;
    isAirport: boolean;
    isReturn: boolean;
    onIncompleteSearchFormData: () => void
    isIntegrationAvailable: boolean;
    bestValue?: boolean;
    isPremium?: boolean;
}

export const VehicleCardWrapper = (props: VehicleCardWrapperProps) => {
    const {
        isReturn,
        duration,
        isAirport,
        bestValue = false,
        isPremium = false,
        vehicleType,
        isIntegration,
        isIntegrationAvailable,
        onIncompleteSearchFormData,
    } = props;
    const isTablet = useIsTablet()
    const isMobile = useIsMobile()
    const isSmallScreen = isMobile || isTablet

    if (isIntegrationAvailable) {
        return (
            <VehicleCard
                isReturn={isReturn}
                isAirport={isAirport}
                isIntegration={isIntegration}
                duration={duration}
                vehicleType={vehicleType}
                onIncompleteSearchFormData={onIncompleteSearchFormData}
                bestValue={bestValue}
                isPremium={isPremium}
            />
        );
    }

    return (!isSmallScreen ? (
            <VehicleCardNative
                isReturn={isReturn}
                isAirport={isAirport}
                isIntegration={isIntegration}
                duration={duration}
                vehicleType={vehicleType}
                onIncompleteSearchFormData={onIncompleteSearchFormData}
            />
        ) : (
            <VehicleCard
                isReturn={isReturn}
                isAirport={isAirport}
                isIntegration={isIntegration}
                duration={duration}
                vehicleType={vehicleType}
                onIncompleteSearchFormData={onIncompleteSearchFormData}
            />
        )
    )
}