import Column from "../../../../features/containers/components/Column.tsx";
import Accordion from "../../../faq/components/Accordion.tsx";
import Portal from "../../../../components/generic/portal/Portal.tsx";
import datasetParser from "../../../../utils/dataset/datasetParser.ts";
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts";
import {replacePlaceholders} from "../functions/replacePlaceholders.tsx";
import {useEffect} from "react";

export const FAQ = () => {
    const {
        faq,
        content,
        cheapestPrice,
        mostExpensivePrice,
        toStation,
        fromStation,
        journeyLength,
        nearbyStation
    } = datasetParser({id: "faq-portal"});

    const tableOfContent = useTranslation(`${faq}`) as Record<string, {question: string, answer: string}>;

    const data = replacePlaceholders(tableOfContent, fromStation, toStation, cheapestPrice, mostExpensivePrice, journeyLength, nearbyStation);

    useEffect(() => {
        document.getElementById('faq-placeholder')?.remove();
    }, []);

    return (
        <Portal
            domNodeId={'faq-portal'}
        >
            <Column>
                <ul>
                    <Accordion data={data} translated/>
                </ul>
            </Column>
        </Portal>
    )
}


