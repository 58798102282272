import Column from "../../../features/containers/components/Column"
import {getTranslatedRoute} from "../../../routing/functions/getTranslatedRoute"
import useLocale from "../../../hooks/selector/useLocale"
import datasetParser from "../../../utils/dataset/datasetParser"
import {useToggle} from "@getbybus/gbb-green";
import StaticDatePicker from "../../../pages/staticPages/stationToStation/components/StaticDatePicker.tsx";
import {useState} from "react";
import {VehicleCardHeader} from "./VehicleCardHeader.tsx";
import {VehicleCardInfo} from "./VehicleCardInfo.tsx";
import {VehicleCardActions} from "./VehicleCardActions.tsx";

interface VehicleCardProps {
    vehicleGroup: string
    vehicleGroupId: number
    vehicleImagePath: string
    vehicleMinPaxCapacity: number
    vehicleMaxPaxCapacity: number
    vehiclePrice: string
    vehicleAmenities: number[]
    vehicleRating: number,
    vehicleModelMake: string
    vehicleRatingCount: number
}

const VehicleCard = (props: VehicleCardProps) => {
    const {
        vehicleGroup,
        vehicleGroupId,
        vehicleImagePath,
        vehicleMinPaxCapacity,
        vehicleMaxPaxCapacity,
        vehiclePrice,
        vehicleAmenities,
        vehicleRating,
        vehicleModelMake,
        vehicleRatingCount,
    } = props

    const {
        searchUrl: {
            fromId,
            fromSlug,
            toId,
            toSlug,
            pickUpDate,
            // noOfPersons
        },
    } = datasetParser({id: "vehicle-group-portal"})

    const locale = useLocale()
    const {getPath} = getTranslatedRoute()
    const bookingSlugTranslation = getPath("booking")
    const bookingData = {
        locale: locale,
        booking: bookingSlugTranslation,
        fromSlug: `${fromSlug}-${fromId}`,
        toSlug: `${toSlug}-${toId}`,
        passengers: vehicleMinPaxCapacity,
        vehicleGroupId: vehicleGroupId,
    };
    const {value, set} = useToggle()
    const [isOpen, setIsOpen] = useState(false)

    const handleMouseOver = () => {
        set(true)
    }

    const handleMouseOut = () => {
        set(false)
    }

    const setOpen = () => setIsOpen(!isOpen)

    return (
        <>
            <StaticDatePicker
                closeModal={setOpen}
                isOpen={isOpen}
                params={bookingData}
            />
            <div
                key={vehicleGroupId}
                className="card__vehicle--sts"
                onClick={setOpen}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                <Column
                    justify
                    center
                    className="gb--card card__vehicle"
                >
                    <VehicleCardHeader
                        vehicleGroup={vehicleGroup}
                        vehicleModelMake={vehicleModelMake}
                        vehicleRating={vehicleRating}
                        vehicleRatingCount={vehicleRatingCount}
                    />
                    <VehicleCardInfo
                        vehicleGroup={vehicleGroup}
                        vehicleImagePath={vehicleImagePath}
                        vehicleMinPaxCapacity={vehicleMinPaxCapacity}
                        vehicleMaxPaxCapacity={vehicleMaxPaxCapacity}
                        vehicleAmenities={vehicleAmenities}
                    />
                    <VehicleCardActions vehiclePrice={vehiclePrice}/>
                </Column>
            </div>
        </>
    )
}

export default VehicleCard
