import VehicleType from "../../../../../types/VehicleType.type.ts";
import {FilterCategoryType} from "../../filterResults/types/FilterCategory.type.ts";
import logo from "../../../../../layout/header/features/topBar/components/Logo.tsx";

export const getSortedVehicles = (vehicles: VehicleType[], vehicleCategories: FilterCategoryType[], categoryId: number | string, isDesktop) => {
    const category = vehicleCategories.find((category) => category.id == categoryId);
    // Sort vehicles by price
    const sortedVehicles = [...vehicles].sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    // Find the cheapest vehicle
    const cheapestVehicle = sortedVehicles[0];
    // Find the cheapest premium vehicle
    const cheapestPremiumVehicle = sortedVehicles.length > 1 ? sortedVehicles.find(vehicle => vehicle.isPremium && vehicle.id != cheapestVehicle.id) : undefined;
    // If no premium vehicle is found, find the second cheapest standard vehicle
    const secondCheapestVehicle = cheapestPremiumVehicle ? cheapestPremiumVehicle : sortedVehicles.length > 1 ? sortedVehicles[1] : undefined;

    const selectedVehicles = secondCheapestVehicle && isDesktop ? [cheapestVehicle, secondCheapestVehicle] : [cheapestVehicle]
    const restVehicles = sortedVehicles.filter(vehicle => !selectedVehicles.includes(vehicle));

    return {selectedVehicles, restVehicles, category, cheapestVehicle, cheapestPremiumVehicle};
}