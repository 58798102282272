import Column from "../../../features/containers/components/Column.tsx";
import IconStarRating from "../icons/IconStarRating.tsx";
import Translation from "../../../features/translation/components/Translation.tsx";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";
import {
    calculateRatingValue
} from "../../../pages/staticPages/stationToStation/features/MozioVehicleCards/functions/calculateRatingValue.ts";

interface VehicleCardHeaderProps {
    vehicleGroup: string
    vehicleModelMake: string
    vehicleRating: number
    vehicleRatingCount: number
}

export const VehicleCardHeader = (props: VehicleCardHeaderProps) => {
    const {
        vehicleGroup,
        vehicleRating,
        vehicleModelMake,
        vehicleRatingCount
    } = props

    const newLabel = useTranslation('email.default.new') as string;

    return (
        <Column center className={'card__header'}>
            <div className={'card__ratings'}>
                {vehicleRatingCount > 0 && (
                    <>
                        <p>({vehicleRatingCount})</p>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <IconStarRating
                                width={16}
                                height={16}
                                color={'#7ed321'}
                                key={index}
                                ratingValueWidth={calculateRatingValue(vehicleRating, index)}
                                fill={'#ddd'}
                            />)
                        )}
                    </>
                )}
                {vehicleRatingCount <= 0 && (
                    <div className={'gb--vehicle-new'}>
                        <h3>
                            {newLabel.toUpperCase()}
                        </h3>
                    </div>
                )}
            </div>
            <p className="gb--emphasize card__title">{vehicleGroup}</p>
            {vehicleModelMake && vehicleModelMake.length > 1 && (
                <p className='card__subtitle'>
                    {vehicleModelMake}
                    {' '}
                    <Translation translationKey={'booking.chooseVehicle.orSimilar'}/>
                </p>
            )}
        </Column>
    )
}