import {allRouteIds} from "../../../../../../../routing/constants/allRouteIds.ts";
import {generateLanguageLink} from "./generateLanguageLink.ts";
import {generatePath, RouteMatch} from "react-router-dom";
import {useAppSelector} from "../../../../../../../hooks/selector/useAppSelector.ts";
import {getTranslatedRoute} from "../../../../../../../routing/functions/getTranslatedRoute.ts";
import {UIMatch} from "react-router";
import UrlObjectType from "../../../../../../../types/UrlObject.type.ts";

// We are generating the link for the language selection here,
// If it is a client side supported page and which is static like 'contact' and 'faq' we have the translations in the urlObjects
// If it is a static page like STS or Station, we have the translations in the translatedRoutes from the page div for that static page see => templates/frontend/StationToStationPage/show.html.twig 72 line
// If it is a dynamic page like 'booking' we need to generate the link based on the current route, replace locale related variables
export const generateLanguageDropdownRoute = (langKey: string, matches: UIMatch[], urlObjects: UrlObjectType[], staticPages: string[], params) => {
    const {
        fromStation,
        toStation
    } = useAppSelector((state) => state?.form)
    const {getPath} = getTranslatedRoute();

    let route = `/${langKey}`;
    if (staticPages.includes(matches[matches.length-1].id)){
        route = urlObjects[langKey][matches[matches.length-1].id].url;
    } else if (matches[matches.length-1].id === allRouteIds.staticPages) {
        route = generateLanguageLink(langKey)
    } else if (matches[matches.length-1].id === allRouteIds.vehicle  && fromStation?.localeSlugs && toStation?.localeSlugs) {
        route = generatePath('/:locale/:booking/:fromSlug/:toSlug/:date/:passengers', {
            locale: langKey,
            booking: getPath('booking', langKey),
            fromSlug: `${fromStation.localeSlugs[langKey]}-${fromStation.id}`,
            toSlug: `${toStation.localeSlugs[langKey]}-${toStation.id}`,
            date: params.date,
            passengers: params.passengers
        })
    } else if (matches[matches.length-1].id === allRouteIds.bookingInfo && fromStation?.localeSlugs && toStation?.localeSlugs) {
        route = generatePath('/:locale/:booking/:fromSlug/:toSlug/:date/:passengers/:vehicleGroupId', {
            locale: langKey,
            booking: getPath('booking', langKey),
            fromSlug: `${fromStation.localeSlugs[langKey]}-${fromStation.id}`,
            toSlug: `${toStation.localeSlugs[langKey]}-${toStation.id}`,
            date: params.date,
            passengers: params.passengers,
            vehicleGroupId: params.vehicleGroupId
        })
    }

    return route;
}