import SearchResultsResponseType from "../types/SearchResultsResponse.type.ts";
import PoolMozioSearchResultsResponseType from "../types/PoolMozioSearchResultsResponse.type.ts";
import {cloneDeep} from "lodash";

export default function handleIntegrationVehicles(data: PoolMozioSearchResultsResponseType) {
    const updateGbtVehicleData = (state: SearchResultsResponseType) => {
        const clonedVehicleGroups = cloneDeep(state.data?.gbt?.vehicles);
        data?.data?.results.forEach((result) => {
            if (clonedVehicleGroups) {
                clonedVehicleGroups[result.vehicleCategoryId] = clonedVehicleGroups[result.vehicleCategoryId] ? [...clonedVehicleGroups[result.vehicleCategoryId], result] : [result];
            }
        });

        return ({
            ...state,
            data: {
                ...state.data,
                gbt: {
                    ...state.data?.gbt,
                    vehicles: clonedVehicleGroups
                },
                integrations: {
                    mozio: {
                        ...state.data?.integrations?.mozio,
                        moreComing: false
                    }
                }
            }
        });
    };

    return {
        updateGbtVehicleData,
    }
}