import useBookingInfoInput from "../features/bookingInfoInputField/hooks/useBookingInfoInput.ts"
import {CountryType, emptyCountryObject} from "@getbybus/gbb-green"
import {useEffect, useState} from "react"
import lodash from "lodash"
import useTranslation from "../../../features/translation/hooks/useTranslation.ts"
import {setContactFormData, setCountry, setCountryCode, setIsoCode} from "../reducers/functions/bookingSlice.ts"
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts"
import {CountryCode, parsePhoneNumber, isPossiblePhoneNumber} from "libphonenumber-js/max";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {validatePhone} from "./validatePhone.ts";
import {useScroll} from "../../../features/scroll/hooks/useScroll.ts";

export const usePhone = () => {
    const {executeScroll, elRef} = useScroll({})
    const dispatch = useAppDispatch()
    const {
        country,
        countryCode,
        isoCode,
        submitTimestamp,
    } = useAppSelector((state) => state?.bookingForm);

    const {
        value: phone,
        displayError,
        errorMessage,
    } = useBookingInfoInput({
        formType: "contactInformationForm",
        fieldValidationMethod: () => validatePhoneNumber(),
        field: "phoneNumber",
    })

    const [countryPhone, setCountryPhone] = useState<CountryType>(emptyCountryObject)

    const errorMessagePhone = useTranslation(errorMessage) as string

    const validatePhoneNumber = () => {
        if (validatePhone(phone, countryPhone)) {
            const phoneNumber = parsePhoneNumber(phone, countryPhone.isoCode as CountryCode)
            return !!phoneNumber.country && phoneNumber.isValid() && phoneNumber.isPossible()
        }

        return false
    }

    const setCountryPhoneTest = (country: CountryType) => {
        console.log("country", country)
    }

    const setPhoneHandler = (value: string) => {
        dispatch(
            setContactFormData({
                value,
                displayError,
                isValid: validatePhoneNumber(),
                type: "phoneNumber",
            }),
        )
    }

    useEffect(() => {
        if (!lodash.isEqual(countryPhone, emptyCountryObject)) {
            dispatch(
                setContactFormData({
                    type: "phoneNumber",
                    value: phone,
                    displayError: !validatePhoneNumber(),
                    isValid: validatePhoneNumber(),
                }),
            )
        }
    }, [phone, countryPhone])

    useEffect(() => {
        if (country, isoCode, countryCode) {
            setCountryPhone({
                name: country,
                dialCode: countryCode,
                isoCode,
            })
        }
    }, []);

    useEffect(() => {
        if (!validatePhoneNumber()) {
            executeScroll();
        }
    }, [submitTimestamp]);

    /**
     * Updates the country code, also updates the phone number prefix if the country code changes
     * @param country
     */
    const setCountryPhoneHandler = (country: CountryType) => {
        if (countryPhone.dialCode && countryPhone.dialCode !== country.dialCode && phone.includes(countryPhone.dialCode)) {
            setPhoneHandler(phone.replace(countryPhone.dialCode, country.dialCode))
        } else if (phone.length === 0) {
            setPhoneHandler(country.dialCode)
        }

        setCountryPhone(country)
        dispatch(setCountry(country.name))
        dispatch(setCountryCode(country.dialCode))
        dispatch(setIsoCode(country.isoCode))
    }
    const onBlur = () => {
        // dispatch(
        //     setContactFormData({
        //         type: "phoneNumber",
        //         value: phone,
        //         displayError: !validatePhone(phone),
        //         errorMessage,
        //         isValid: validatePhone(phone),
        //     }),
        // )
    }

    return {
        elRef,
        phone,
        onBlur,
        displayError,
        countryPhone,
        submitTimestamp,
        setPhoneHandler,
        setCountryPhone,
        errorMessagePhone,
        setCountryPhoneHandler
    }
}
