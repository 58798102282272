import VehicleCardsPortal from "./components/VehicleCardsPortal";
import {FAQ} from "./components/FAQ.tsx";

const StationToStationPage = () => {
    return (
        <>
            {document.getElementById('vehicle-group-portal') && (
                <VehicleCardsPortal/>
            )}
            {document.getElementById('faq-portal') && (
                <FAQ/>
            )}
        </>
    )
}

export default StationToStationPage;