import Card from "../../../../../components/generic/Card.tsx"
import Row from "../../../../../features/containers/components/Row.tsx"
import BookingMobileVehicleInfo from "./BookingMobileVehicleInfo.tsx"
import BookingMobileVehiclePaymentInfo from "./BookingMobileVehiclePaymentInfo.tsx"
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts"
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import VehicleCard from "../../../../searchResults/features/vehicleCard/components/VehicleCard.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";

interface BookingMobileTransferDetailsProps {
    data: any;
}

const BookingMobileTransferDetails = (props: BookingMobileTransferDetailsProps) => {
    const {
        data
    } = props;

    const vehicleType = useAppSelector((state) => state?.vehicleType)
    const isMobile = useIsMobile();
    return (
        <div className="gb--row gb--row-justify">
            <div className="gb--column gb--column-center card-width">
                <Card additionalClass={`${!isMobile ? 'gb--booking-vehicle__desktopWrapper' : 'gb--booking__preview-card'}`}>
                    {
                        <VehicleCard
                            vehicleType={vehicleType}
                            isIntegration={vehicleType.isIntegration}
                            isReturn={data.data.isRoundTrip}
                            isAirport={data?.data?.pickupInfo.isAirport || data?.data?.dropOffInfo.isAirport}
                            duration={data?.data?.duration}
                            onIncompleteSearchFormData={() => {}}
                            displayOnly
                        />
                        // <Row
                        //     justify
                        //     center
                        //     className="gb--booking-vehicle__content"
                        // >
                        //     <BookingMobileVehicleInfo
                        //         vehicleType={vehicleType}
                        //     />
                        //     <BookingMobileVehiclePaymentInfo
                        //         vehicleType={vehicleType}
                        //         returnTrip={returnTrip}
                        //     />
                        // </Row>
                    }
                </Card>
            </div>
        </div>
    )
}

export default BookingMobileTransferDetails
