import IconExchange from "../../../../../components/generic/icons/IconExchange.tsx";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {togglePlaces} from "../../../../../reducers/formSlice.ts";

function PlaceSwitchButton() {
    const dispatch = useAppDispatch();

    const toggleStationHandler = (e) => {
        e.stopPropagation()
        dispatch(togglePlaces())
    };

    return (
        <button
            type="button"
            className={`react-autosuggest__switch-button`}
            onClick={toggleStationHandler}
        >
            <IconExchange
                fill={'#7ed321'}
                width="18"
                height="18"
                style={{verticalAlign: 'middle', background: '#fff'}}
            />
        </button>
    );
}

export default PlaceSwitchButton;