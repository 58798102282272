import Row from "../../../features/containers/components/Row.tsx";
import PillLabel from "../PillLabel.tsx";
import IconPax from "../icons/IconPax.tsx";
import IconSuitcaseV2 from "../icons/IconSuitcaseV2.tsx";
import Column from "../../../features/containers/components/Column.tsx";
import {useGetAmenityObject} from "../../../pages/bookingForm/features/BookingAmenities/hooks/useGetAmenityObject.ts";

interface VehicleCardInfoProps {
    vehicleGroup: string
    vehicleImagePath: string
    vehicleMinPaxCapacity: number
    vehicleMaxPaxCapacity: number
    vehicleAmenities: number[]
}

export const VehicleCardInfo = (props: VehicleCardInfoProps) => {
    const {
        vehicleGroup,
        vehicleImagePath,
        vehicleMinPaxCapacity,
        vehicleMaxPaxCapacity,
        vehicleAmenities,
    } = props;

    const {getAmenityObjectIcon} = useGetAmenityObject()

    const handleImageError = (e) => {
        e.target.src = '/public/car_loader.png';
    };

    return (
        <Column
            wrap
            className="vehicle-group__desc"
        >
            <img
                width="auto"
                height="auto"
                src={vehicleImagePath ?? '/public/car_loader.png'}
                alt={vehicleGroup}
                onError={handleImageError}
            />
            <Row justify className={'card__perks'}>
                <PillLabel
                    color='blue'
                    label={
                        <Row className={'card__capacity'}>
                            <div>
                                <IconPax />
                                {vehicleMinPaxCapacity} - {vehicleMaxPaxCapacity}
                            </div>
                            &#9679;
                            <div>
                                <IconSuitcaseV2 />
                                {vehicleMaxPaxCapacity}
                            </div>
                        </Row>
                    }
                />
                <div className={'card__amenities'}>
                    {vehicleAmenities?.map((amenity, index) => {
                        if (index < 3) {
                            const IconComponent = getAmenityObjectIcon(amenity)
                            return <IconComponent width={24} height={24} key={amenity}/>
                        }
                    })}
                    {3 < vehicleAmenities?.length && <p>+{vehicleAmenities?.length - 3}</p>}
                </div>
            </Row>
        </Column>
    )
}