import {useToggle} from "@getbybus/gbb-green";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useEffect, useRef} from "react";
import CustomDropdown from "../../../../../components/generic/CustomDropdown.tsx";
import IconLocationMarker from "../../../../../components/generic/icons/IconLocationMarker.tsx";
import FadingError from "../../../../../features/errors/components/FadingError.tsx";
import useHandleUserPlaceInput from "../hooks/useHandleUserPlaceInput.ts";
import useHandleUserPlaceSelect from "../hooks/useHandleUserPlaceSelect.ts";
import PlaceSelectOptions from "./PlaceSelectOptions.tsx";
import usePlacePlaceholder from "../hooks/usePlacePlaceholder.ts";
import ValidPlaceIndicator from "./ValidPlaceIndicator.tsx";
import {PlaceType} from "../../../../../types/Place.type.ts";
import {
    dismissAllOverlays,
    setDeparturePlaceInput,
    setDestinationPlaceInput,
    setSingleCalendar
} from "../../../../../reducers/mobileSlice.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {usePlaceSelect} from "../hooks/usePlaceSelect.ts";
import PlaceSwitchButton from "./PlaceSwitchButton.tsx";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";

interface PlaceSelectProps {
    direction: "to" | "from",
    name: "departure" | "destination",
    label?: string,
    placeholder: string,
    isOverlay?: boolean,
    showIsValid?: boolean,
    searchStrict?: boolean,
    isValid?: boolean,
    showIsIncomplete?: boolean,
    data?: any
    toRef?: HTMLInputElement,
    inputRef: HTMLInputElement
    autoFocus?: boolean;
    isIncompleteDialog?: boolean;
}

const PlaceSelect = (props: PlaceSelectProps) => {
    const {
        name,
        label,
        inputRef,
        placeholder,
        direction,
        searchStrict,
        isOverlay = false,
        showIsValid = false,
        isValid,
        showIsIncomplete,
        data,
        toRef,
        autoFocus = false,
        isIncompleteDialog = false
    } = props;

    const isMobile = useIsMobile();

    const {
        error,
        toPlace,
        toStation,
        fromPlace,
        fromStation,
        errorMessage,
        departureDate,
        isDropdownOpen,
        submitTimestamp,
        placePlaceholder,
        setIsDropdownOpen,
        handleClickOutside,
        setPlacePlaceholder
    } = usePlaceSelect({name, isOverlay, searchStrict, direction, data, toRef, inputRef});

    const {
        response,
        handleChange,
    } = useHandleUserPlaceInput({setIsDropdownOpen, setPlacePlaceholder, searchStrict, name, fromStation, toStation, data, inputRef, autoFocus});

    const {
        handleSelect,
    } = useHandleUserPlaceSelect({name, response, setIsDropdownOpen, setPlacePlaceholder, toRef, fromPlace, toPlace, departureDate, isOverlay});

    const generateClassName = (label, direction, isIncompleteDialog) => {
        let className = `search__select--${direction}`;
        if (label) {
            className += ' search__select';
        } else {
            className += ' search__select__center';
        }
        if (isIncompleteDialog) {
            className += ` search__select--${direction}-incomplete`;
        }
        return className;
    }

    return (
        <div className={generateClassName(label, direction, isIncompleteDialog)}>
            <CustomDropdown
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
                handleClickOutside={handleClickOutside}
                additionalClasses={`${isOverlay ? 'search__select-container' : ''} ${showIsIncomplete ? "search__select--incomplete-address-border" : ""}`}
                field={(
                    <>
                        {showIsIncomplete && (
                            <div className="search__select--incomplete-address-container">
                                <div className="search__select--incomplete-address">
                                    Update address
                                </div>
                            </div>
                        )}
                        <IconLocationMarker
                            className={label ? 'search__select--icon' : 'search__select--icon__center'}
                        />
                        <input
                            ref={inputRef}
                            onChange={handleChange}
                            type="text"
                            name={name}
                            className={"gb--input-standalone"}
                            placeholder={placeholder}
                            value={placePlaceholder}
                            autoComplete={"off"}
                            autoFocus={autoFocus}
                        />
                        {showIsValid && (
                            <ValidPlaceIndicator isValid={isValid!}/>
                        )}
                        {!!label && (
                            <span className="search__select--label gb--emphasize gb--emphasize-default">
                                {label} {/* Will add translations later on*/}
                            </span>
                        )}
                    </>
                )}
            >
                <PlaceSelectOptions
                    isLoading={response?.isLoading}
                    data={response?.data}
                    handleSelect={handleSelect}
                />
            </CustomDropdown>
            <FadingError
                isError={error}
                showError={submitTimestamp}
                errorMessageTranslationKey={errorMessage}
            />
            {name === 'departure' && !isMobile && !isIncompleteDialog && (
                <PlaceSwitchButton/>
            )}
        </div>
    )
}

export default PlaceSelect
