import {useEffect, useState} from "react"
import BookingForm from "./BookingForm.tsx"
import {validateSearchResultsErrorType} from "../../../api/functions/validateSearchResultsErrorType.ts";
import Modal from "../../../components/generic/modal/Modal.tsx";
import {Button, Row} from "@getbybus/gbb-green";
import Translation from "../../../features/translation/components/Translation.tsx";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";
import useNavigateToSearch from "../hooks/useNavigateToSearch.ts";
import useLocale from "../../../hooks/selector/useLocale.ts";
import useStopAllExistingBookings from "../hooks/useStopAllExistingBookings.ts";
import BookingFormLoader from "./BookingFormLoader.tsx";
import {useLocation, useParams} from "react-router";
import useGetSearchResultsVehiclesData from "../../searchResults/hooks/useGetSearchResultsVehiclesData.ts";
import VehicleType from "../../../types/VehicleType.type.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {setVehicleType} from "../../../reducers/vehicleTypeSlice.ts";
import {setAmenities} from "../reducers/functions/bookingSlice.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {setUserCanSkipCookies, toggleIsCookieModalOpen} from "../../../reducers/pageSlice.ts";
import {useSelectVehicleOnReload} from "../hooks/useSelectVehicleOnReload.ts";

function BookingFormWrapper() {
    const locale = useLocale()
    const genericError = useTranslation("base.validation.somethingWentWrong") as string;
    const [loadingVehicle, setLoadingVehicle] = useState<boolean>(true);
    const {cookiesConsent} = useAppSelector((state) => (state.page))
    const params = useParams();
    const dispatch = useAppDispatch();
    const {
        data,
        isLoading,
        isError,
        error,
    } = useGetSearchResultsVehiclesData()

    const {navigateToSearchResults} = useNavigateToSearch()

    useEffect(() => {
        if (!cookiesConsent) {
            dispatch(toggleIsCookieModalOpen(true));
            dispatch(setUserCanSkipCookies(false));
        }
    }, []);

    useStopAllExistingBookings();
    useSelectVehicleOnReload(error, data, isLoading, setLoadingVehicle);

    if (isError && validateSearchResultsErrorType(error)) {
        if (!error?.data?.shouldRedirectToContactPage) {
            const message = error?.data?.message || genericError
            return (
                // TODO Remove this modal, and render normal component so the user can use the search form (maybe some image of no results :)
                <Modal
                    title={message}
                    message={(
                        <Row style={{justifyContent: "center"}}>
                            <Button
                                onClick={() => (window.location.href = `//${window.location.host}/${locale}`)}
                            >
                                <Translation translationKey={"booking.confirmation.backToHome"}/>
                            </Button>
                        </Row>
                    )}
                />
            )
        } else if (error?.data?.shouldRedirectToContactPage) {
            // TODO this should not be the way to handle this, but for now it is :?
            return <></>
        }
    } else if (!isLoading && data && !loadingVehicle) {
        return <BookingForm isLoading={isLoading} data={data}/>
    } else {
        return (
            <BookingFormLoader/>
        )
    }
}

export default BookingFormWrapper