import Row from "../../../../../features/containers/components/Row.tsx";
import VehicleCardLoader from "../../../../../components/generic/loaders/VehicleCardLoader.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import Image from "../../../../../features/images/components/Image.tsx";
import {useLoadingBooleans} from "../hooks/useLoadingBooleans.ts";
import useIsDesktop from "../../../../../hooks/selector/useIsDesktop.ts";

export const VehiclesLoader = () => {
    const isDesktop = useIsDesktop();
    const {
        lastStepTrigger,
        fetchedGbtData,
        fetchedGbtIsUninitialized,
        fetchedGbtIsLoading
    } = useLoadingBooleans();

    return (
        <Column className={`${!lastStepTrigger ? 'no-image' : ''}`} justify style={{width: '100%'}}>
            <Row style={{gap: '1rem'}}>
                <VehicleCardLoader/>
                {isDesktop && (<VehicleCardLoader/>)}
            </Row>
            <Row style={{gap: '1rem'}}>
                <VehicleCardLoader/>
                {isDesktop && (<VehicleCardLoader/>)}
            </Row>
        </Column>
    )
}