import {useGetPlaces} from "../../../../../pages/bookingForm/hooks/useGetPlaces.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {ChangeEvent, useEffect} from "react";
import StationType from "../types/Station.type.ts";
// import useCachedData from "../../../../../pages/searchResults/hooks/useCachedData.ts";
import {useParams} from "react-router";
import {SearchParamsType} from "../../../../../types/SearchParams.type.ts";
import {getStationIdFromParam} from "../../../../../pages/searchResults/functions/getStationIdFromParam.ts";

interface UseHandleUserPlaceInputParams {
    setIsDropdownOpen: (value: boolean) => void,
    setPlacePlaceholder: (value: string) => void,
    searchStrict: boolean | undefined,
    name: "departure" | "destination",
    fromStation?: StationType
    toStation?: StationType,
    data?: any
    inputRef: HTMLInputElement,
    autoFocus?: boolean
}

export default function useHandleUserPlaceInput(params: UseHandleUserPlaceInputParams) {
    const {
        setIsDropdownOpen,
        setPlacePlaceholder,
        searchStrict,
        name,
        data,
        inputRef,
        autoFocus = false,
        fromStation,
        toStation
    } = params

    const {
        fromSlug,
        toSlug
    } = useParams<SearchParamsType>()

    const {
        _token
    } = useAppSelector((state) => state?.page)

    const stationObject = name === "departure" ? fromStation : toStation

    const station = fromSlug && toSlug ? name === "departure" ? getStationIdFromParam(fromSlug as string) : getStationIdFromParam(toSlug as string) : null
    const info = name === "departure" ? {key: 'pickupInfo', coordinatesKey: 'pickUpCoordinates'} : {key: 'dropOffInfo', coordinatesKey: 'dropOffCoordinates'}

    const {debounceRequest, response} = useGetPlaces({
        _token,
        searchStrict,
        stationId: searchStrict && station !== '31026' ? Number(station || stationObject?.id) : undefined,
        coordinates: data ? data?.data[info.key][info.coordinatesKey] : undefined
    })

    useEffect(() => {
        if (document.activeElement === inputRef?.current) {
            debounceRequest({target: {value: inputRef.current.value}})
        }
    }, [document.activeElement]);

    useEffect(() => {
        if (autoFocus) {
            inputRef?.current?.focus();
        }
    }, []);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsDropdownOpen(true)
        setPlacePlaceholder(event.target.value)
        debounceRequest(event)
    }

    return {
        response,
        handleChange,
    }
}