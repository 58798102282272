import Button from "../../../../components/generic/Button.tsx"
import IconArrowBack from "../../../../components/generic/icons/IconArrowBack.tsx"
import {useNavigate} from "react-router"
import LoaderButton from "../../../../components/generic/loaders/LoaderButton.tsx"
import useIsDesktop from "../../../../hooks/selector/useIsDesktop.ts"
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"
import {useNavigateBackOnBookingForm} from "../../../../layout/bookingNavigation/hooks/useNavigateBackOnBookingForm.ts";

interface BookingNavigationButtonsProps {
    isLoading: boolean
    handleSubmit: (event) => void
}

const BookingNavigationButtons = ({isLoading, handleSubmit}: BookingNavigationButtonsProps) => {
    const navigate = useNavigate()
    const bookButtonLabel = useTranslation("booking.form.button") as string
    const backButtonLabel = useTranslation("booking.form.backToVehicle") as string
    const isSmallDisplay = !useIsDesktop()
    const {navigateBackOnBookingForm} = useNavigateBackOnBookingForm()

    const handleNavigateBack = () => {
        navigateBackOnBookingForm()
    }

    return (
        <div className="gb--booking__footer">
            <div className="book-button">
                {isLoading ? (
                    <button className="btn btn--blue-darker return-button-submit">
                        <LoaderButton
                            width="16"
                            height="14"
                        />
                    </button>
                ) : (
                    <button
                        className="btn btn--blue-darker return-button-submit"
                        type="button"
                        onClick={handleSubmit}
                    >
                        {bookButtonLabel}
                    </button>
                )}
            </div>
        </div>
    )
}

export default BookingNavigationButtons
