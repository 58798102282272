import Card from "../../../components/generic/Card.tsx";
import Column from "../../../features/containers/components/Column.tsx";
import LinePlaceholder from "../../../features/placeholders/components/LinePlaceholder.tsx";
import CollapsableComponent from "../../../components/generic/CollapsableComponent.tsx";
import useIsMobile from "../../../hooks/selector/useIsMobile.ts";
import Row from "../../../features/containers/components/Row.tsx";

const BookingFormLoader = () => {
    const isMobile = useIsMobile()

    return (
        <>
            <div className={"gb--booking-background"}>
                <Row justify reverse className={"info-cards"}>
                    <div className={"gb--card gb--booking__wrapper gb--booking__preview"}>
                        <div>
                            <div className='gb--row gb--row-justify' key={"vehicle-loader"}>
                                <div className="gb--column gb--column-center card-width">
                                    <Card
                                        additionalClass={`${!isMobile ? 'gb--booking-vehicle__desktopWrapper' : 'gb--booking__preview-card'}`}>
                                        {
                                            <Row
                                                justify
                                                center
                                                className="gb--booking-vehicle__content"
                                            >
                                                <Column className="gb--booking-vehicle__column">
                                                    <Row
                                                        justify
                                                        center
                                                    >
                                                        <Column
                                                            justify
                                                            className="gb--booking-vehicle__mobileInfo"
                                                        >
                                                            <Row className="gb--booking-vehicle__info">
                                                                <Column>
                                                                    <Column className='gb--booking-vehicle__container'>
                                                                        <div className='gb--booking-vehicle__info'>
                                                                            <Column className='gb--booking-vehicle__name'>
                                                                                <LinePlaceholder placeholderClassName={'gb--booking-vehicle-loader__name'} height={'50px'}/>
                                                                            </Column>
                                                                            <Column className='gb--booking-vehicle__image'>
                                                                                <LinePlaceholder placeholderClassName={'gb--booking-vehicle-loader__image'} height={'80px'}/>
                                                                            </Column>
                                                                        </div>
                                                                    </Column>
                                                                </Column>
                                                            </Row>
                                                        </Column>
                                                    </Row>
                                                </Column>
                                                <Column className="gb--booking-vehicle__paymentInfo">
                                                    <Row
                                                        center
                                                        justify
                                                    >
                                                        <Column>
                                                            <LinePlaceholder placeholderClassName={'gb--booking-vehicle-loader__price'} height={'80px'}/>
                                                        </Column>
                                                    </Row>
                                                    <Row>
                                                        <LinePlaceholder placeholderClassName={'gb--vehicle__button gb--vehicle-loader__button'} height={'20px'}/>
                                                    </Row>
                                                </Column>
                                            </Row>
                                        }
                                    </Card>
                                    <CollapsableComponent
                                        label={''}
                                        collapsable
                                        wrapper
                                        hrClassName={'gb--divider__hr'}
                                    >
                                        <Column>
                                            <Row className="gb--booking__info">
                                                <Column>
                                                    <div>
                                                        <LinePlaceholder placeholderClassName={"gb--booking-vehicle-loader__shortText"} height={"14px"}/>
                                                    </div>
                                                    <div>
                                                        <LinePlaceholder height={"20px"}/>
                                                    </div>
                                                </Column>
                                            </Row>
                                            <Row>
                                                <div>
                                                    <LinePlaceholder placeholderClassName={"gb--booking-vehicle-loader__shortText"} height={"14px"}/>
                                                </div>
                                            </Row>
                                            <Row>
                                                <Column>
                                                    <div>
                                                        <LinePlaceholder placeholderClassName={"gb--booking-vehicle-loader__shortText"} height={"14px"}/>
                                                    </div>
                                                    <div>
                                                        <LinePlaceholder placeholderClassName={"gb--booking-vehicle-loader__shortText"} height={"14px"}/>
                                                    </div>
                                                </Column>
                                            </Row>
                                        </Column>
                                        <div
                                            style={{paddingTop: "1rem"}}
                                            className={"gb--row-full_width gb--booking__contact"}
                                        >
                                            <LinePlaceholder height={"50px"}/>
                                        </div>
                                    </CollapsableComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"gb--booking__fields"}>
                        <div className={"gb--card gb--booking__wrapper"}>
                            <div className={'gb--booking-item'}>
                                <CollapsableComponent label={''} hrClassName={'gb--divider__hr'}>
                                    <Row
                                        gap
                                        justify
                                        className="gb--row-full_width gb--booking__contact gb--booking__input"
                                    >
                                        <div style={{padding: '.5rem'}}>
                                            <LinePlaceholder height={'60px'}/>
                                        </div>
                                        <div style={{padding: '.5rem'}}>
                                            <LinePlaceholder height={'60px'}/>
                                        </div>
                                    </Row>
                                    <Row
                                        gap
                                        justify
                                        className="gb--row-full_width gb--booking__contact gb--booking__input"
                                    >
                                        <div style={{padding: '.5rem'}}>
                                            <LinePlaceholder height={'60px'}/>
                                        </div>
                                        <div style={{padding: '.5rem'}}>
                                            <LinePlaceholder height={'60px'}/>
                                        </div>
                                    </Row>
                                </CollapsableComponent>
                            </div>
                            <div className={'gb--booking-item'}>
                                <div>
                                    <Row
                                        style={{padding: '.5rem'}}
                                        gap
                                        justify
                                        className="gb--row-full_width gb--booking__contact gb--booking__input"
                                    >
                                        <LinePlaceholder height={'60px'}/>
                                    </Row>
                                </div>
                            </div>
                            <div className={'gb--booking-item'}>
                                <div>
                                    <Row
                                        style={{padding: '.5rem'}}
                                        gap
                                        justify
                                        className="gb--row-full_width gb--booking__contact gb--booking__input"
                                    >
                                        <LinePlaceholder height={'60px'}/>
                                    </Row>
                                </div>
                            </div>
                            <div className={'gb--booking-item'}>
                                <div>
                                    <Row
                                        style={{padding: '.5rem'}}
                                        gap
                                        justify
                                        className="gb--row-full_width gb--booking__contact gb--booking__input"
                                    >
                                        <LinePlaceholder height={'60px'}/>
                                    </Row>
                                </div>
                            </div>
                            <div className={'gb--booking-item'}>
                                <div>
                                    <Row
                                        style={{padding: '.5rem'}}
                                        gap
                                        justify
                                        className="gb--row-full_width gb--booking__contact gb--booking__input"
                                    >
                                        <LinePlaceholder height={'60px'}/>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default BookingFormLoader;