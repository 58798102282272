import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import useFormPlaceValidator from "../../../hooks/useFormPlaceValidator.ts";
import {useEffect, useState} from "react";
import {getVehicleTextCTA} from "../functions/getVehicleTextCTA.ts";
import useIsDesktop from "../../../../../hooks/selector/useIsDesktop.ts";
import useVehicleSelected from "../hooks/useVehicleSelected.ts";
import Column from "../../../../../features/containers/components/Column.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import {VehicleCardInfo} from "./VehicleCardInfo.tsx";
import {VehicleCardPricePerks} from "./VehicleCardPricePerks.tsx";
import IconChevron from "../../../../../components/generic/icons/IconChevron.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";
import IconFire from "../../../../../components/generic/icons/IconFire.tsx";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

interface VehicleCardProps {
    vehicleType: VehicleType;
    duration: number;
    isIntegration: boolean;
    isAirport: boolean;
    isReturn: boolean;
    onIncompleteSearchFormData: () => void
}

export const VehicleCardNative = (props: VehicleCardProps) => {
    const {
        vehicleType,
        duration,
        isIntegration,
        isAirport,
        isReturn,
        onIncompleteSearchFormData,
    } = props;

    const [num, fraction] = vehicleType.price.split('.');
    const from = useTranslation('booking.chooseVehicle.from') as string;

    const {
        latestSearchFormUsed: {
            departureDate,
        }
    } = useAppSelector((state) => state?.searchResults)

    const {lastSearchInvalidDestination, lastSearchInvalidDeparture} = useFormPlaceValidator()
    const isComplete = !((lastSearchInvalidDestination || lastSearchInvalidDeparture) && isIntegration)
    const [isDateComplete, setIsDateComplete] = useState<boolean>(false)
    const selectTrip = getVehicleTextCTA(isComplete, isDateComplete, isIntegration);
    const {vehicleSelected} = useVehicleSelected({
        vehicleType,
        duration,
        isIntegration,
        isAirport,
        isDateComplete,
        isComplete,
        onIncompleteSearchFormData,
    });

    useEffect(() => {
        setIsDateComplete(!!departureDate)
    }, []);

    const handleImageError = (e) => {
        e.target.src = '/public/car_loader.png';
    };

    return (
        <div className={'gb--row gb--card gb--vehicle gb--vehicle__native gb--card-enlarge__small'} onClick={vehicleSelected}>
            <Row center style={{gap: '1rem'}}>
                <Column alignStart>
                    <img
                        src={vehicleType.imagePath}
                        alt={vehicleType.type}
                        onError={handleImageError}
                    />
                    <Row className="gb--vehicle__arrowContainer">
                        <Column className="gb--vehicle__arrow">
                            <span>Best value</span>
                        </Column>
                    </Row>
                </Column>
                <VehicleCardInfo
                    ratingCount={vehicleType.ratingCount}
                    type={vehicleType.type}
                    modelMake={vehicleType.modelMake}
                    rating={vehicleType.rating}
                    id={vehicleType.id}
                    alignEnd={true}
                />
            </Row>
            <Row center  style={{gap: '1rem'}}>
                <VehicleCardPricePerks
                    isComplete={isComplete}
                    isIntegration={isIntegration}
                    price={vehicleType.price}
                    maxPax={vehicleType.maxPax}
                    amenities={vehicleType.amenities}
                    isReturn={isReturn}
                    alignEnd
                />
                <Column alignEnd style={{gap: '3px'}}>
                    <Row alignEnd center>
                        <b>
                            <span style={{marginTop: 'auto', fontSize: '24px'}}>{num},</span>
                            <sup style={{fontSize: '16px', paddingBottom: '.5rem'}}>{fraction}</sup>
                            <span style={{marginTop: 'auto', fontSize: '24px'}}> &#8364;</span>
                        </b>
                    </Row>
                    <button className='btn btn--blue-darker gb--vehicle__button'>
                        {selectTrip}
                    </button>
                </Column>
            </Row>
        </div>
    )
}