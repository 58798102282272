import Row from "../../../../../features/containers/components/Row.tsx";
import IconFire from "../../../../../components/generic/icons/IconFire.tsx";
import IconPax from "../../../../../components/generic/icons/IconPax.tsx";
import IconSuitcaseV2 from "../../../../../components/generic/icons/IconSuitcaseV2.tsx";
import VehicleAmenities from "./VehicleAmenities.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import {AmenityType} from "../../../../../types/Amenity.type.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

interface VehicleCardPricePerksProps {
    isComplete: boolean;
    isIntegration: boolean;
    price: string;
    maxPax: number;
    amenities: AmenityType[];
    isReturn: boolean;
    displayPrice?: boolean;
    alignEnd?: boolean;
    displayOnly?: boolean;
}

export const VehicleCardPricePerks = (props: VehicleCardPricePerksProps) => {
    const {
        isComplete,
        isIntegration,
        price,
        maxPax,
        amenities,
        isReturn,
        alignEnd = false,
        displayPrice = false,
        displayOnly = false,
    } = props;
    const [num, fraction] = price.split('.');
    const from = useTranslation('booking.chooseVehicle.from') as string;
    const oneWayTransfer = useTranslation('booking.chooseVehicle.oneWay') as string;
    const returnTransfer = useTranslation('booking.title.returnTransfer') as string;
    const trip = isReturn ? returnTransfer : oneWayTransfer;

    return (
        <Column style={alignEnd ? {height: '100%', justifyContent: 'end'} : {}}>
            {displayPrice && (
                <Row alignEnd center>
                    <b>
                        <span style={{marginTop: 'auto', fontSize: '1.2em'}}>{num},</span>
                        <sup style={{fontSize: '.7em', paddingBottom: '.5rem'}}>{fraction}</sup>
                        <span style={{marginTop: 'auto', fontSize: 'larger'}}> &#8364;</span>
                    </b>
                </Row>
            )}
            <Row alignEnd style={{gap: '4px'}}>
                <div>
                    {maxPax}
                    <IconPax/>
                </div>
                <div>
                    {maxPax}
                    <IconSuitcaseV2/>
                </div>
            </Row>
            {isReturn && (
                <Row alignEnd>
                    <span
                        style={{fontSize: '14px'}}
                        className="gb--emphasize-inactive "
                    >
                        {trip}
                    </span>
                </Row>
            )}
            {!displayOnly && amenities.length > 0 && (
                <Row alignEnd>
                    <VehicleAmenities
                        amenities={amenities}
                        showMore={false}
                    />
                </Row>
            )}
        </Column>
    )
}