import Row from "../../../../../features/containers/components/Row.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";
import useFormPlaceValidator from "../../../hooks/useFormPlaceValidator.ts";
import useVehicleSelected from "../hooks/useVehicleSelected.ts";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import Column from "../../../../../features/containers/components/Column.tsx";
import {getVehicleTextCTA} from "../functions/getVehicleTextCTA.ts";
import IconChevron from "../../../../../components/generic/icons/IconChevron.tsx";
import useIsDesktop from "../../../../../hooks/selector/useIsDesktop.ts";
import {VehicleCardInfo} from "./VehicleCardInfo.tsx";
import {VehicleCardPricePerks} from "./VehicleCardPricePerks.tsx";
import IconCheckMarkCircle from "../../../../../components/generic/icons/IconCheckMarkCircle.tsx";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import Translation from "../../../../../features/translation/components/Translation.tsx";

interface VehicleCardProps {
    vehicleType: VehicleType;
    duration: number;
    isIntegration: boolean;
    isAirport: boolean;
    isReturn: boolean;
    onIncompleteSearchFormData: () => void
    bestValue?: boolean;
    isPremium?: boolean;
    displayOnly?: boolean;
}

const VehicleCard = (props: VehicleCardProps) => {
    const {
        vehicleType,
        duration,
        isIntegration,
        isAirport,
        isReturn,
        onIncompleteSearchFormData,
        bestValue,
        isPremium,
        displayOnly = false,
    } = props;

    const {
        latestSearchFormUsed: {
            departureDate,
        }
    } = useAppSelector((state) => state?.searchResults)

    const {lastSearchInvalidDestination, lastSearchInvalidDeparture} = useFormPlaceValidator()
    const isComplete = !((lastSearchInvalidDestination || lastSearchInvalidDeparture) && isIntegration)
    const [isDateComplete, setIsDateComplete] = useState<boolean>(false)
    const selectTrip = getVehicleTextCTA(isComplete, isDateComplete, isIntegration);
    const isDesktop = useIsDesktop();
    const isMobile = useIsMobile();
    const {vehicleSelected} = useVehicleSelected({
        vehicleType,
        duration,
        isIntegration,
        isAirport,
        isDateComplete,
        isComplete,
        onIncompleteSearchFormData,
    });

    const labelText = isPremium ? 'Exclusive' : 'Best value';

    useEffect(() => {
        setIsDateComplete(!!departureDate)
    }, []);

    const handleImageError = (e) => {
        e.target.src = '/public/car_loader.png';
    };

    const className = displayOnly ? `gb--vehicle gb--vehicle__display` : 'gb--card gb--vehicle gb--card-enlarge__small';

    return (
        <div className={className} onClick={!displayOnly ? vehicleSelected : null}>
            <Column>
                <Row justify>
                    <VehicleCardInfo
                        ratingCount={vehicleType.ratingCount}
                        type={vehicleType.type}
                        modelMake={vehicleType.modelMake}
                        rating={vehicleType.rating}
                        id={vehicleType.id}
                    />
                    <VehicleCardPricePerks
                        isComplete={isComplete}
                        isIntegration={isIntegration}
                        price={vehicleType.price}
                        maxPax={vehicleType.maxPax}
                        amenities={vehicleType.amenities}
                        isReturn={isReturn}
                        displayPrice
                        displayOnly={displayOnly}
                    />
                </Row>
                <Row justify style={{alignItems: 'end', gap: '1rem'}}>
                    <Column alignStart>
                        <img
                            src={vehicleType.imagePath}
                            alt={vehicleType.type}
                            onError={handleImageError}
                        />
                        {(bestValue) && (
                            <Row className="gb--vehicle__arrowContainer">
                                <Column className="gb--vehicle__arrow">
                                    {/*<span>*/}
                                        <Translation translationKey={'booking.chooseVehicle.bestValue'}/>
                                    {/*</span>*/}
                                </Column>
                            </Row>
                        )}
                        {(isPremium && !bestValue) && (
                            <Row className="gb--vehicle__arrowContainer">
                                <Column className="gb--vehicle__arrow">
                                    <span>
                                        <Translation translationKey={'booking.chooseVehicle.exclusive'}/>
                                    </span>
                                </Column>
                            </Row>
                        )}
                    </Column>
                    <Column alignEnd>
                        {displayOnly ? (
                            <IconCheckMarkCircle
                                width="5rem"
                                height="5rem"
                            />
                        ) : (
                            <button className='btn btn--blue-darker gb--vehicle__button'>
                                {isDesktop
                                    ? selectTrip
                                    : <IconChevron chevronOrientation={"right"} fill={'white'}/>
                                }
                            </button>
                        )}
                    </Column>
                </Row>
            </Column>
        </div>
    )
};

export default VehicleCard;