import BookingTransferInfo from "./BookingTransferInfo.tsx"
import {useAppSelector} from "../../../../hooks/selector/useAppSelector.ts"
import BookingVehicleTypePreview from "./BookingVehicleTypePreview.tsx"
import BookingTransferPreviewPrice from "./BookingTransferPreviewPrice.tsx"
import {setReviewMailConsent} from "../../reducers/functions/bookingSlice.ts"
import {useAppDispatch} from "../../../../hooks/dispatch/useAppDispatch.ts"
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"
import ToggleSwitch from "../../../../components/generic/ToggleSwitch.tsx"
import {useCallback, useEffect, useState} from "react";
import {debounce} from "lodash";
import VehicleCard from "../../../searchResults/features/vehicleCard/components/VehicleCard.tsx";

interface BookingTransferPreviewProps {
    data: any;
}

const BookingTransferPreview = (props: BookingTransferPreviewProps) => {
    const dispatch = useAppDispatch()
    const vehicleType = useAppSelector((state) => state?.vehicleType);
    const {
        reviewMailConsent,
        singleTransferForm: {
            pickUpAddress: {
                value: pickUpAddress
            },
            dropOffAddress: {
                value: dropOffAddress
            }
        }
    } = useAppSelector((state) => state?.bookingForm);

    const {
        data
    } = props;

    const transferInformation = useTranslation("booking.form.transferInfo") as string
    const returnTransferInformation = useTranslation("booking.form.returnTransferInfo") as string
    const review = useTranslation("booking.form.review") as string
    const [fromLabel, setFromLabel] = useState();
    const [toLabel, setToLabel] = useState();
    const [fromSubLabel, setFromSubLabel] = useState();
    const [toSubLabel, setToSubLabel] = useState();

    const updateLabels = (form, to) => {
        if (!data?.data?.pickupInfo?.isAirport) {
            setFromSubLabel(form);
        }
        if (!data?.data?.dropOffInfo?.isAirport) {
            setToSubLabel(to);
        }
    }

    // It had to be done manually since the useDebounce hook did not take any parameters for the callback function
    // and I was using state values which are not in sync that way
    const debounceRequest = debounce((pickUpAddress, dropOffAddress) => updateLabels(pickUpAddress, dropOffAddress), 2000);

    const handleDebounce = useCallback((pickUpAddress, dropOffAddress) => {
        debounceRequest(pickUpAddress, dropOffAddress);
    }, []);

    useEffect(() => {
        handleDebounce(pickUpAddress, dropOffAddress);
    }, [pickUpAddress, dropOffAddress]);

    useEffect(() => {
        setFromLabel(vehicleType.isIntegration ? data?.data.pickupInfo.pickUpPlace : data?.data.pickupInfo.pickUpStation.label);
        setToLabel(vehicleType.isIntegration ? data?.data.dropOffInfo.dropOffPlace : data?.data.dropOffInfo.dropOffStation.label);
    }, [vehicleType])

    return (
        <>
            <VehicleCard
                vehicleType={vehicleType}
                isIntegration={vehicleType.isIntegration}
                isReturn={data.data.isRoundTrip}
                isAirport={data?.data?.pickupInfo.isAirport || data?.data?.dropOffInfo.isAirport}
                duration={data?.data?.duration}
                onIncompleteSearchFormData={() => {}}
                displayOnly
            />
            <BookingTransferInfo
                startDate={data?.data.pickupInfo.pickUpDateTime}
                pickUpTime={data?.data.pickupInfo.pickUpDateTime}
                departure={fromLabel}
                label={transferInformation}
                destination={toLabel}
                duration={data?.data.duration}
                departureSubtitle={fromSubLabel}
                destinationSubtitle={toSubLabel}
            />
            {data?.data.isRoundTrip && (
                <BookingTransferInfo
                    startDate={data?.data.returnPickUpDateTime}
                    pickUpTime={data?.data.returnPickUpDateTime}
                    departure={toLabel}
                    label={returnTransferInformation}
                    destination={fromLabel}
                    duration={data?.data.duration}
                    departureSubtitle={toSubLabel}
                    destinationSubtitle={fromSubLabel}
                />
            )}
            <div className="gb--preview__container">
                <ToggleSwitch
                    id="reviewConsent"
                    isChecked={reviewMailConsent}
                    onChange={() => {
                        dispatch(setReviewMailConsent(!reviewMailConsent))
                    }}
                    label={review}
                    minimize
                />
            </div>
            <BookingTransferPreviewPrice
                vehicleType={vehicleType}
                returnTrip={data?.data.isRoundTrip}
            />
        </>
    )
}

export default BookingTransferPreview
