import UrlObjectType from "../../types/UrlObject.type.ts";
import {split} from "lodash";
import rootExtractor from "../../utils/dataset/rootExtractor.ts";

export const getTranslatedRoute = () => {
    const urlObjects: Record<"contact" | "homePage" | "faq" | "booking" | "termsAndConditions" | "privacyPolicy", UrlObjectType> = rootExtractor('urlsObject', true);
    // const locale = rootExtractor('locale');
    const getPath = (object: "contact" | "homePage" | "faq" | "booking" | "termsAndConditions" | "privacyPolicy", locale = rootExtractor('locale')) => {
        return split(urlObjects[locale][object].url, "/")[2]
    };

    return {
        getPath,
    }
}