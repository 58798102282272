import useClearBookingFormOnNewSearch from "./useClearBookingFormOnNewSearch.ts"
import {apiSlice, useLazyInitialSearchQuery} from "../../../api/reducers/apiSlice.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {
    clearStations,
    setFromStation, setSearchFinishedTimestamp,
    setSuccessfulSubmitTimestamp,
    setToStation
} from "../../../reducers/formSlice.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import useValidateForm from "./useValidateForm.ts";
import {cloneDeep} from "lodash";
import {useNavigateToSearch} from "./useNavigateToSearch.ts";
import {
    resetMozioSequentialUnsuccessfulFetch,
    setCategoryPrice, setCurrentSearch, setLatestSearchFormUsed, setPartnerId,
    setPreselectCategory, setRefreshResults,
    setSearchedWithStations, setSelectedVehicleCategory,
    setShouldUseStationParams
} from "../../../pages/searchResults/reducers/functions/searchResultsSlice.ts";
import {useGenerateQueryBody} from "../functions/generateQueryBody.ts";
import isBefore from "date-fns/isBefore";

export default function useSubmitSearch() {
    const {checkForErrors} = useValidateForm()
    const dispatch = useAppDispatch()
    const {clearBookingFormOnNewSearch} = useClearBookingFormOnNewSearch()
    const {navigateToSearchResults} = useNavigateToSearch()
    const [query, {isFetching, isLoading}] = useLazyInitialSearchQuery()
    const {generateBody} = useGenerateQueryBody();
    const {
        departureDate: pickUpDateTime,
    } = useAppSelector((state) => state?.form)
    const {
        currentSearch
    } = useAppSelector((state) => state?.searchResults)
    const form = useAppSelector((state) => state?.form)


    const handleNavigateToSearch = (data: any) => {
        let fromSlug = cloneDeep(data?.pickupInfo?.pickUpStation)
        let toSlug = cloneDeep(data?.dropOffInfo?.dropOffStation)
        dispatch(setFromStation(data?.pickupInfo?.pickUpStation))
        dispatch(setToStation(data?.dropOffInfo?.dropOffStation))
        if (!data?.pickupInfo?.pickUpStation || data?.pickupInfo?.pickUpStation.slug.includes('unknown') || data?.pickupInfo?.pickUpStation.slug === 'unknown') {
            fromSlug = {...fromSlug, slug: data?.pickupInfo?.placeSlug}
        }
        if (!data?.dropOffInfo?.dropOffStation || data?.dropOffInfo?.dropOffStation.slug.includes('unknown') || data?.dropOffInfo?.dropOffStation.slug === 'unknown') {
            toSlug = {...toSlug, slug: data?.dropOffInfo?.placeSlug}
        }
        navigateToSearchResults(fromSlug, toSlug)
    }

    const submitForm = () => {
        if (checkForErrors()) {
            dispatch(resetMozioSequentialUnsuccessfulFetch())
            dispatch(setSuccessfulSubmitTimestamp())
            dispatch(clearStations())
            dispatch(setShouldUseStationParams(false))
            dispatch(setSearchedWithStations(false))
            dispatch(setCategoryPrice({}))
            dispatch(setPreselectCategory(true))
            dispatch(setSelectedVehicleCategory(0))
            dispatch(setRefreshResults(false))
            dispatch(setPartnerId(0))
            clearBookingFormOnNewSearch()
            // Placeholder to reset api state, so the mozio results invalidate
            dispatch(apiSlice.util.resetApiState())
            dispatch(setCurrentSearch(generateBody(true)));
            dispatch(setLatestSearchFormUsed(form))

            query(generateBody(true)).unwrap().then(({data}) => {
                dispatch(setShouldUseStationParams(false))
                dispatch(setSearchFinishedTimestamp(new Date().getMilliseconds()));
                handleNavigateToSearch(data)
                // Not in use currently
                // if (data.gbt.vehicleGroups[0]) {
                //     dispatch(setPartnerId(data.gbt.vehicleGroups[0].partnerId));
                // }
            })
        }
    }

    const refreshResults = () => {
        dispatch(apiSlice.util.resetApiState())
        dispatch(resetMozioSequentialUnsuccessfulFetch())
        dispatch(setSuccessfulSubmitTimestamp())
        dispatch(clearStations())
        dispatch(setCategoryPrice({}))
        dispatch(setPreselectCategory(true))
        dispatch(setRefreshResults(false))
        dispatch(setSelectedVehicleCategory(0))
        dispatch(setPartnerId(0))
        clearBookingFormOnNewSearch()


        if (isBefore(new Date(pickUpDateTime), new Date())) {
            window.location.reload()
        } else {
            query(currentSearch).unwrap().then(({data}) => {
                dispatch(setSearchFinishedTimestamp(new Date().getMilliseconds()))
                handleNavigateToSearch(data)
                // Not in use currently
                // if (data.gbt.vehicleGroups[0]) {
                //     dispatch(setPartnerId(data.gbt.vehicleGroups[0].partnerId));
                // }
            })
        }
    }

    return {
        submitForm,
        refreshResults,
        handleNavigateToSearch,
        isFetching: isFetching || isLoading,
    }
}
