import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {InitialSearchRequestType} from "../../../../../api/types/InitialSearchRequest.type.ts";
import {
    usePollMozioResultsMutation,
} from "../../../../../api/reducers/apiSlice.ts";
import useLocale from "../../../../../hooks/selector/useLocale.ts";
import {useEffect} from "react";

export default function useGetMozioSearchResults(gbtDataIsLoading: boolean, gbtDataIsError: FetchBaseQueryError | SerializedError | undefined, gbtVehiclesData: any, originalArgs: InitialSearchRequestType) {
    const [getMoreMozioVehicles, mozioData] = usePollMozioResultsMutation();
    const locale = useLocale();

    useEffect(() => {
        if (!gbtDataIsLoading && gbtVehiclesData?.data?.integrations?.mozio?.moreComing) {
            setTimeout(() => {
                getMoreMozioVehicles({
                    searchId: gbtVehiclesData?.data?.mozioSearchId,
                    locale,
                    originalInitialArgs: originalArgs
                })
            }, 3000);
        }
    }, [gbtDataIsLoading]);

    return {
        mozioVehiclesData: mozioData?.data,
        mozioIsLoading: mozioData?.isLoading,
        mozioIsUninitialized: mozioData?.isUninitialized,
    }
}