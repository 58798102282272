import VehicleType from "../../../types/VehicleType.type.ts";
import Row from "../../../features/containers/components/Row.tsx";
import {VehicleCardWrapper} from "./VehicleCardWrapper.tsx";
import CollapsableComponent from "../../../components/generic/CollapsableComponent.tsx";
import useIsDesktop from "../../../hooks/selector/useIsDesktop.ts";
import {useToggle} from "@getbybus/gbb-green";
import {FilterCategoryType} from "../features/filterResults/types/FilterCategory.type.ts";
import {useRef} from "react";
import IconChevron from "../../../components/generic/icons/IconChevron.tsx";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";
import {getSortedVehicles} from "../features/vehicleCard/functions/getSortedVehicles.ts";

interface VehicleCardGroupProps {
    id: number | string;
    vehicles: VehicleType[];
    isReturn: boolean;
    isAirport: boolean;
    duration: number;
    onIncompleteSearchFormData: () => void;
    categoryPrices: Record<number, string>;
    isIntegrationAvailable: boolean;
    vehicleCategories: FilterCategoryType[];
}

export const VehicleCardGroup = (props: VehicleCardGroupProps) => {
    const {
        id,
        vehicles,
        isReturn,
        duration,
        isAirport,
        categoryPrices,
        vehicleCategories,
        isIntegrationAvailable,
        onIncompleteSearchFormData,
    } = props;
    const contentHeight = useRef<HTMLDivElement>(null);
    const isDesktop = useIsDesktop();
    const {onChange, value} = useToggle();
    const {
        selectedVehicles,
        restVehicles,
        category,
        cheapestVehicle,
        cheapestPremiumVehicle
    } = getSortedVehicles(vehicles, vehicleCategories, id, isDesktop);

    const showMore = useTranslation('booking.chooseVehicle.showMore');
    const hideMore = useTranslation('booking.chooseVehicle.hideMore');
    const toggleText = value ? hideMore : showMore;

    return (
        <CollapsableComponent
            key={id}
            hrClassName={'gb--vehicle-wrapper-hr'}
            wrapperClassName={'gb--vehicle-wrapper'}
            customButton={restVehicles.length > 0 && (
                <button
                    type={'button'}
                    className={`gb--vehicle__results-group`}
                    onClick={onChange}
                >
                    <span>{toggleText?.replace('%number%', `${restVehicles?.length}`)}</span>
                    <IconChevron fill={'#4A79E2'} chevronOrientation={value ? "up" : "down"}/>
                </button>
            )}
            label={category?.name}
        >
            <div key={id}>
                <div key={id} className={isIntegrationAvailable ? 'gb--vehicle-wrapper__open' : 'gb--vehicle-wrapper'}>
                    {selectedVehicles.map((vehicleType: VehicleType) => {
                        if (vehicleType) {
                            return (
                                <VehicleCardWrapper
                                    isReturn={isReturn}
                                    isAirport={isAirport}
                                    isIntegration={!!vehicleType?.isIntegration}
                                    duration={duration}
                                    vehicleType={vehicleType}
                                    onIncompleteSearchFormData={onIncompleteSearchFormData}
                                    categoryPrices={categoryPrices}
                                    isIntegrationAvailable={isIntegrationAvailable}
                                    bestValue={vehicleType?.id === cheapestVehicle?.id}
                                    isPremium={vehicleType?.isPremium && vehicleType?.id === cheapestPremiumVehicle?.id}
                                />
                            )
                        }
                    })}
                </div>
                <div
                    ref={contentHeight}
                    className={isIntegrationAvailable ? 'gb--vehicle-wrapper__open' : 'gb--vehicle-wrapper'}
                    style={{ height: value ? contentHeight?.current?.scrollHeight : "0px" }}
                >
                    {restVehicles.map((vehicleType: VehicleType) => (
                        <VehicleCardWrapper
                            isReturn={isReturn}
                            isAirport={isAirport}
                            isIntegration={!!vehicleType.isIntegration}
                            duration={duration}
                            vehicleType={vehicleType}
                            onIncompleteSearchFormData={onIncompleteSearchFormData}
                            categoryPrices={categoryPrices}
                            isIntegrationAvailable={isIntegrationAvailable}
                            isPremium={vehicleType?.isPremium && vehicleType?.id === cheapestPremiumVehicle?.id}
                        />
                    ))}
                </div>
            </div>
        </CollapsableComponent>
    )
}