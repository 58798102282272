import VehicleCards from "./VehicleCards.tsx";
import {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import useFormPlaceValidator from "../hooks/useFormPlaceValidator.ts";
import IncompleteSearchFormDataDialog
    from "../features/incompleteDialog/components/IncompleteSearchFormDataDialog.tsx";
import {setCategoryPrice} from "../reducers/functions/searchResultsSlice.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import useSearchTripsEvent from "../../../features/events/hooks/useSearchTripsEvent.ts";
import {VehiclesLoader} from "../features/loader/components/VehiclesLoader.tsx";
import Image from "../../../features/images/components/Image.tsx";

interface SearchResultsProps {
    data: any,
    shouldDisplayLoader: boolean,
    originalArgs: any
    dialogRef: HTMLDialogElement
    onIncompleteSearchFormData: () => void
}

function SearchResults(props: SearchResultsProps) {
    const dispatch = useAppDispatch();
    const {
        data,
        originalArgs,
        dialogRef,
        onIncompleteSearchFormData,
        shouldDisplayLoader
    } = props;
    const {
        categoryPrices,
    } = useAppSelector((state) => (state.searchResults));

    const {
        latestSearchFormUsed: {
            departureDate
        }
    } = useAppSelector((state) => state?.searchResults)
    // const event = useSearchTripsEvent(data?.data?.gbt?.vehicleGroups);
    const [hideAnimation, setHideAnimation] = useState(false)

    const {lastSearchInvalidDeparture, lastSearchInvalidDestination} = useFormPlaceValidator()
    const isComplete = !(lastSearchInvalidDestination || lastSearchInvalidDeparture)
    const [isDateComplete, setIsDateComplete] = useState(false)

    useEffect(() => {
        setIsDateComplete(!!departureDate)
        // event()
    }, []);

    useEffect(() => {
        if (!shouldDisplayLoader) {
            setHideAnimation(true);
        } else {
            setHideAnimation(false);
        }
    }, [shouldDisplayLoader])


    return (
        <div className="gb--vehicle__results">
            <div
                className={`gb--vehicle__results-animation ${hideAnimation ? 'gb--vehicle__results-animation-hide' : ''}`}>
                <Image
                    imageFileName="search_anim_1"
                    imageFileFormat="gif"
                />
            </div>
            {(data?.data?.gbt && !shouldDisplayLoader) ? (
                <>
                    <VehicleCards
                        isReturn={data.data.isRoundTrip}
                        isAirport={data?.data?.pickupInfo.isAirport || data?.data?.dropOffInfo.isAirport}
                        duration={data?.data?.duration}
                        vehicles={data?.data?.gbt?.vehicles}
                        onIncompleteSearchFormData={onIncompleteSearchFormData}
                        categoryPrices={categoryPrices}
                        isIntegrationAvailable={data?.data?.shouldShowIntegrationResults}
                        vehicleCategories={data?.data?.vehicleCategories}
                    />

                    <IncompleteSearchFormDataDialog
                        dialogRef={dialogRef}
                        originalArgs={originalArgs}
                        isComplete={isComplete}
                        isDateComplete={isDateComplete}
                    />
                </>
            ) : (
                <VehiclesLoader/>
            )}

        </div>
    )
}

export default SearchResults