import Column from "../../../features/containers/components/Column.tsx";
import Translation from "../../../features/translation/components/Translation.tsx";
import Row from "../../../features/containers/components/Row.tsx";

interface VehicleCardActionProps {
    vehiclePrice: string
}

export const VehicleCardActions = (props: VehicleCardActionProps) => {
    const {
        vehiclePrice,
    } = props;

    const [integerPart, decimalPart] = vehiclePrice.split('.');

    return (
        <Row className="card__action">
            <Column alignEnd>
                        <span className="vehicle-group__price">
                            <span className="euro-icon">
                                &#8364;
                            </span>
                            {integerPart},
                            {decimalPart &&
                                <sup>
                                    {decimalPart}
                                </sup>
                            }
                        </span>
            </Column>
            <button
                type="button"
                className={`btn btn--blue-darker`}
            >
                <Translation translationKey="booking.chooseVehicle.selectDate" />
            </button>
        </Row>
    )
}