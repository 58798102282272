export const MozioVehicleObject = {
    1: {
        typeId: 1,
        type: "Sedan",
        photoUrl: "/public/executive_2.png",
        minNoOfPersons: 1,
        maxNoOfPersons: 4,
        rating: 4.8,
        modelMake: "Skoda Octavia",
        ratingCount: 342,
    },
    2: {
        typeId: 2,
        type: "Van",
        photoUrl: "/public/minivan_1.png",
        minNoOfPersons: 4,
        maxNoOfPersons: 8,
        rating: 4.5,
        modelMake: "Mercedes V class",
        ratingCount: 256,
    },
    3: {
        typeId: 3,
        type: "Minibus",
        photoUrl: "/public/executive_1.png",
        minNoOfPersons: 9,
        maxNoOfPersons: 15,
        rating: 3.6,
        modelMake: "Renault Master",
        ratingCount: 98,
    },
}