import BookingAmenity from "./BookingAmenity.tsx";
import {AmenityType} from "../../../../../types/Amenity.type.ts";
import CollapsableComponent from "../../../../../components/generic/CollapsableComponent.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";
import Translation from "../../../../../features/translation/components/Translation.tsx";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

interface BookingAmenitiesProps {
    amenities: AmenityType[]
    isIntegration: boolean
    isReturn: boolean
    vehicles?: VehicleType[]
}

const BookingAmenities = (props: BookingAmenitiesProps) => {
    const {
        amenities,
        isIntegration,
        isReturn,
    } = props;
    const additionalAmmenities = useTranslation('booking.title.additionalRideAmenities') as string;

    return (
        <div key={'Amenities'} className={'gb--booking-item'}>
            <CollapsableComponent
                label={additionalAmmenities}
                hrClassName={'gb--divider__hr'}
            >
                {
                    amenities.map((amenity) => (
                        <BookingAmenity
                            key={amenity.id}
                            id={amenity.id}
                            name={amenity.name}
                            price={Number(amenity.price)}
                            isReturn={isReturn}
                            description={amenity?.description}
                            isIntegration={isIntegration}
                        />
                    ))
                }
            </CollapsableComponent>
        </div>

    );
};

export default BookingAmenities;