import {ReactNode} from "react";
import BackButton from "../../../routing/components/BackButton.tsx";
import Column from "../../../features/containers/components/Column.tsx";
import Row from "../../../features/containers/components/Row.tsx";
import {
    useHandleBookingNavigationItemClass
} from "../functions/useHandleBookingNavigationItemClass.ts";
import MatchType from "../../../types/Match.type.ts";
import {Button} from "@getbybus/gbb-green";
import IconSearch from "../../../components/generic/icons/IconSearch.tsx";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {toggleMobileSearchFormOverlay} from "../../../reducers/mobileSlice.ts";
import useRouteMatch from "../../../routing/hooks/useRouteMatch.ts";
import {allRouteIds} from "../../../routing/constants/allRouteIds.ts";


interface BookingNavigationItemProps {
    id: string
    matches: MatchType[]
    title: string
    subtitle: string
    children?: ReactNode
    goBack?: () => void
}

const BookingNavigationItemMobile = (props: BookingNavigationItemProps) => {
    const {
        id,
        matches,
        title,
        subtitle,
        children,
        goBack
    } = props

    const {vehicle} = allRouteIds;
    const isSearchResults = useRouteMatch(vehicle);
    const {handleBookingNavigationItemClass} = useHandleBookingNavigationItemClass()
    const navItemClass = handleBookingNavigationItemClass(id, matches);

    const dispatch = useAppDispatch()
    const updateTrip = () => {
        dispatch(toggleMobileSearchFormOverlay())
    }

    return (
        <Column
            className={navItemClass}
        >
            <Row>
                <BackButton backClick={goBack} icon={"back"} fill={'#555'}/>
            </Row>
            <Row>
                <Column>
                    <Row className={'gb--booking-navigation__title'}>
                        {title}
                    </Row>
                    <Row className={'gb--booking-navigation__subtitle'}>
                        {subtitle}
                    </Row>
                </Column>
                {isSearchResults && (
                    <Button
                        onClick={updateTrip}
                        variant="grey"
                        size="small"
                        additionalClasses="gb--booking-navigation__search-trip"
                    >
                        <IconSearch/>
                        Update trip
                    </Button>
                )}
            </Row>
            {children && (
                <Row>
                    {children}
                </Row>
            )}
        </Column>
    );
};

export default BookingNavigationItemMobile;