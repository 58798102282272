import useTranslation from "../../../features/translation/hooks/useTranslation.ts";
import {useMatches, useParams} from "react-router";
import format from "date-fns/format";
import BookingNavigationItem from "./BookingNavigationItem.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import BookingNavigationResultsInfo from "./BookingNavigationResultsInfo.tsx";
import useNavigateToHome from "../../../routing/navigation/useNavigateToHome.ts";
import {useNavigateBackOnBookingForm} from "../hooks/useNavigateBackOnBookingForm.ts";
import useIsDesktop from "../../../hooks/selector/useIsDesktop.ts";
import useCachedData from "../../../pages/searchResults/hooks/useCachedData.ts";
import {handleBookingDateFormat} from "../../../pages/searchResults/functions/handleBookingDateFormat.ts";
import {getDateRequestFormat} from "../../../pages/searchResults/functions/getDateRequestFormat.ts";


const BookingNavigationItemList = () => {
    const matches = useMatches();
    const selectVehicle = useTranslation('booking.chooseVehicle.heading') as string;
    const bookingConfirmation = useTranslation('booking.confirmation.confirmation') as string;
    const transferAndPayment = useTranslation('booking.form.heading') as string;
    const {navigateToHome} = useNavigateToHome()
    const {navigateBackOnBookingForm} = useNavigateBackOnBookingForm();
    const isDesktop = useIsDesktop();
    const {
        departureDate,
        numberOfPassengers
    } = useAppSelector((state) => state?.form);
    const {
        latestSearchFormUsed,
        currentSearch
    } = useAppSelector((state) => state?.searchResults);
    const {
        fetchedGbtData,
        fetchedGbtIsLoading,
    } = useCachedData();

    const params = useParams();

    // Todo replace with the data from the root once the app is localised
    const BookingSteps = {
        'vehicle': {
            order: 1,
            text: selectVehicle,
            subtitle: `${format(new Date(currentSearch?.pickUpDateTime || getDateRequestFormat(params.date)), 'hh:mm aa | EEE MMM dd')} | ${numberOfPassengers} pax`,
            children: null,
            goBack: () => navigateToHome()
        },
        'booking-info': {
            order: 2,
            text: transferAndPayment,
            subtitle: `${format(new Date(currentSearch?.pickUpDateTime || getDateRequestFormat(params.date)), 'hh:mm aa | EEE MMM dd')} | ${numberOfPassengers} pax`,
            children: <BookingNavigationResultsInfo/>,
            goBack: () => navigateBackOnBookingForm()
        },
        'successful': {
            order: 3,
            text: bookingConfirmation,
            subtitle: `${format(new Date(currentSearch?.pickUpDateTime || new Date().toISOString()), 'hh:mm aa | EEE MMM dd')} | ${numberOfPassengers} pax`,
            children: null,
            goBack: undefined
        }
    };

    const shouldDisplayNavigation = (!fetchedGbtIsLoading && fetchedGbtData && !fetchedGbtData?.data?.integrations?.mozio?.moreComing) || !isDesktop;

    return (
        <>
            {shouldDisplayNavigation && (
                <div className='gb--booking-navigation__container'>
                    {Object.entries(BookingSteps).map(([step, {text, subtitle, order, children, goBack}]) => (
                        <BookingNavigationItem
                            id={step}
                            title={text}
                            subtitle={subtitle}
                            matches={matches}
                            key={step}
                            order={order}
                            children={children}
                            goBack={goBack}
                        />
                    ))}
                </div>
            )}
        </>

    );
};

export default BookingNavigationItemList;