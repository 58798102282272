import {useEffect, useState} from "react";
import useCachedData from "../../../hooks/useCachedData.ts";

export const useLoadingBooleans = () => {
    const [lastStepTrigger, setLastStepTrigger] = useState(false)
    const {
        fetchedGbtData,
        fetchedGbtIsLoading,
        fetchedGbtIsUninitialized,
    } = useCachedData();
    useEffect(() => {
        if (!fetchedGbtIsLoading && !fetchedGbtIsUninitialized) {
            setTimeout(() => {
                setLastStepTrigger(true);
            }, 2700);
        }
    }, [fetchedGbtIsLoading, fetchedGbtIsUninitialized]);

    return {
        lastStepTrigger,
        fetchedGbtData,
        fetchedGbtIsLoading,
        fetchedGbtIsUninitialized
    }
}