import VehicleType from "../../../types/VehicleType.type.ts";
import {FilterCategoryType} from "../features/filterResults/types/FilterCategory.type.ts";
import {VehicleCardGroup} from "./VehicleCardGroup.tsx";

interface VehicleTypeListProps {
    vehicles?: VehicleType[];
    duration: number;
    isAirport: boolean;
    isReturn: boolean;
    onIncompleteSearchFormData: () => void;
    categoryPrices: Record<number, string>;
    isIntegrationAvailable: boolean;
    vehicleCategories: FilterCategoryType[];
}

const VehicleCards = (props: VehicleTypeListProps) => {
    const {
        vehicles = [],
        duration,
        isAirport,
        isReturn,
        onIncompleteSearchFormData,
        categoryPrices,
        isIntegrationAvailable,
        vehicleCategories,
    } = props;

    return (
        <div>
            {Object.keys(vehicles).map((key) => {
                if (vehicles[key].length > 0) {
                    return (
                        <VehicleCardGroup
                            key={key}
                            id={key}
                            vehicles={vehicles[key]}
                            isReturn={isReturn}
                            duration={duration}
                            isAirport={isAirport}
                            vehicleCategories={vehicleCategories}
                            categoryPrices={categoryPrices}
                            isIntegrationAvailable={isIntegrationAvailable}
                            onIncompleteSearchFormData={onIncompleteSearchFormData}
                        />
                    )
                }
            })}
        </div>
    );
};

export default VehicleCards;