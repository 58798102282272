import useIsMobile from "../../../hooks/selector/useIsMobile.ts";

type Match = {
    data: unknown
    handle: unknown
    id: string
    pathname: string
    params: object
}

export const useHandleBookingNavigationItemClass = () => {
    const className = "gb--booking-navigation";
    const isMobile = useIsMobile();
    const steps = {
        "vehicle": {
            selected: ['booking-info'],
            completed: ['vehicle']
        },
        "booking-info": {
            selected: ['successful'],
            completed: ['vehicle', 'booking-info']
        },
        "successful": {
            selected: isMobile ? ['successful'] : [],
            completed: ['vehicle', 'booking-info', 'successful']
        }
    }

    const handleBookingNavigationItemClass = (id: string, matches: Match[]) => {
        const routeIds: string[] = matches.map((route: Match) => route.id)

        if (steps[routeIds[routeIds.length - 1]].selected.find((routeId) => routeId === id)) {
            return `${className} ${className}__selected`
        }

        if (steps[routeIds[routeIds.length - 1]].completed.find((routeId) => routeId === id)) {
            return `${className} ${className}__completed`
        }

        return className
    }

    return {
        handleBookingNavigationItemClass
    }
}
