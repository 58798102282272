import IconChevron from "../../../../../components/generic/icons/IconChevron.tsx";
import useIsDesktop from "../../../../../hooks/selector/useIsDesktop.ts";
import {useLoadingBooleans} from "../hooks/useLoadingBooleans.ts";
import Translation from "../../../../../features/translation/components/Translation.tsx";

export const BreadcrumbLoader = () => {
    const {
        lastStepTrigger,
        fetchedGbtData,
        fetchedGbtIsUninitialized,
        fetchedGbtIsLoading
    } = useLoadingBooleans();

    return (
        <div className={'gb--booking-navigation__container-loader'}>
            <div
                className={`gb--booking-navigation-loader ${(!fetchedGbtData || !fetchedGbtIsUninitialized) && 'gb--booking-navigation-loader__selected'}`}
            >
                <div>
                    <span>1</span>
                </div>
                <Translation translationKey={'booking.chooseVehicle.loader.1st'}/>
            </div>
            <div>
                <IconChevron width={'14'} chevronOrientation={"right"}/>
            </div>
            <div
                className={`gb--booking-navigation-loader ${(!fetchedGbtIsLoading && !fetchedGbtIsUninitialized) && 'gb--booking-navigation-loader__selected'}`}
            >
                <div>
                    <span>2</span>
                </div>
                <Translation translationKey={'booking.chooseVehicle.loader.2nd'}/>
            </div>
            <div>
                <IconChevron width={'14'} chevronOrientation={"right"}/>
            </div>
            <div
                className={`gb--booking-navigation-loader ${lastStepTrigger && 'gb--booking-navigation-loader__selected'}`}
            >
                <div>
                    <span>3</span>
                </div>
                <Translation translationKey={'booking.chooseVehicle.loader.3rd'}/>
            </div>
        </div>
    )
}