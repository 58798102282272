import MatchType from "../../../types/Match.type.ts";
import IconCheckmark from "../../../components/generic/icons/IconCheckmark.tsx";
import {useHandleBookingNavigationItemClass} from "../functions/useHandleBookingNavigationItemClass.ts";

interface BookingNavigationItemProps {
    id: string
    matches: MatchType[]
    title: string
    order: number
}

const BookingNavigationItemDesktop = (props: BookingNavigationItemProps) => {
    const {
        id,
        matches,
        title,
        order
    } = props
    const {handleBookingNavigationItemClass} = useHandleBookingNavigationItemClass()
    const navItemClass = handleBookingNavigationItemClass(id, matches);

    return (
        <div
            className={navItemClass}
        >
            <div>
                {navItemClass.includes('completed') ? (
                    <IconCheckmark fill={'#7ed321'} width={16} height={16}/>
                ): (
                    <span>{order}</span>
                )}
            </div>
            {title}
        </div>
    );
};

export default BookingNavigationItemDesktop;