import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

export const useGetIncompleteSearchFormDialogContent = (isComplete: boolean, dateSelected: boolean) => {
    if (!isComplete && !dateSelected) {
        return {
            title: useTranslation('booking.search.incompleteDateAndAdress.title') as string,
            description: useTranslation('booking.search.incompleteDateAndAdress.description') as string
        }
    } else if (!isComplete && dateSelected) {
        return {
            title: useTranslation('booking.search.incompleteAddress.title') as string,
            description: useTranslation('booking.search.incompleteAddress.description') as string
        }
    }

    return {
        title: useTranslation('booking.search.incompleteDateAndTime.title') as string,
        description: useTranslation('booking.search.incompleteDateAndTime.description') as string
    }
}