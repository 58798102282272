import {getTranslatedRoute} from "../../../../../../routing/functions/getTranslatedRoute.ts";
import useIsDesktop from "../../../../../../hooks/selector/useIsDesktop.ts";
import {useState} from "react";
import {handleBookingDateFormat} from "../../../../../searchResults/functions/handleBookingDateFormat.ts";
import {generatePath} from "react-router-dom";
import MobileOverlay from "../../../../../../features/mobileOverlays/components/MobileOverlay.tsx";
import DatePickerWrapper from "../../../../../../features/dateTime/components/DatePickerWrapper.tsx";
import Translation from "../../../../../../features/translation/components/Translation.tsx";
import Modal from "../../../../../../components/generic/modal/Modal.tsx";
import IconTimesCircle from "../../../../../../components/generic/icons/IconTimesCircle.tsx";
import {StaticIncompleteModalBody} from "./StaticIncompleteModalBody.tsx";


interface StaticIncompleteModalProps {
    isOpen: boolean
    closeModal: () => void
    params: any
}

function StaticIncompleteModal(props: StaticIncompleteModalProps) {
    const {
        isOpen,
        closeModal,
        params
    } = props;
    const {getPath} = getTranslatedRoute();
    const isDesktop = useIsDesktop();
    const [date, setDate] = useState<string>();

    const handleSelectDate = (value: Date) => {
        setDate(value.toISOString());
    };

    const handleSubmit = () => {
        if (date) {
            const path = generatePath(`/:locale/${getPath("booking")}/:fromSlug/:toSlug/:date/:passengers`, {
                ...params,
                date: handleBookingDateFormat(date, '-'),
            });
            window.location.href = `${path}?from_sts=true`;
        }
    }

    const minDate = new Date();
    if (!isDesktop) {
        return (
            <MobileOverlay
                backClick={closeModal}
                title={<Translation translationKey={'booking.chooseVehicle.selectDepartureDate'}/>}
                slideInDirection={'zoom'}
                wrapperClassName={'page-static__modal'}
            >
                <StaticIncompleteModalBody/>
            </MobileOverlay>
        )
    }


    return (
        <Modal
            className={'page-static__modal'}
            message={
                <div>
                    <div className={'page-static__modal-close'}>
                        <button
                            type={'button'}
                            onClick={(e) => {
                                e.preventDefault()
                                closeModal()
                            }}
                        >
                            <IconTimesCircle
                                height={24}
                                width={24}
                                backgroundFill={'white'}
                                fill="#9b9b9b"
                            />
                        </button>
                    </div>
                    <StaticIncompleteModalBody/>
                </div>
            }
        />
    )
}

export default StaticIncompleteModal